<template>
    <b-modal :title="$t('user.user')" v-model="modalActive" size="lg" no-close-on-backdrop :hide-header-close="loading" :no-close-on-esc="loading">
        <template #default>
            <b-overlay :show="loading">
                <validation-observer ref="validation">
                    <b-form-group :label="$t('user.name')">
                        <validation-provider #default="{ errors }" :name="$t('user.name')" rules="required">
                            <b-form-input v-model="object.name"/>
                            <small class="text-danger">{{errors[0]}}</small>
                        </validation-provider>
                    </b-form-group>
                    <b-form-group :label="$t('user.email')">
                        <validation-provider #default="{ errors }" :name="$t('user.email')" rules="required|email">
                            <b-form-input name="email" :disabled="disabledEmail"  v-model="object.email"/>
                            <small class="text-danger">{{errors[0]}}</small>
                        </validation-provider>
                    </b-form-group>
                    <b-form-group v-if="editing" :label="$t('login.password')">
                        <validation-provider #default="{ errors }" :name="$t('login.password')">
                            <b-form-input autocomplete="new-password" v-model="object.password" :placeholder="$t('user.edit_password_text')" type="password"/>
                            <small class="text-danger">{{errors[0]}}</small>
                        </validation-provider>
                    </b-form-group>
                    <b-form-group v-else :label="$t('login.password')">
                        <validation-provider #default="{ errors }" :name="$t('login.password')" rules="required">
                            <b-form-input autocomplete="new-password" v-model="object.password" type="password"/>
                            <small class="text-danger">{{errors[0]}}</small>
                        </validation-provider>
                    </b-form-group>
                    <b-form-group :label="$t('locales.language')">
                        <validation-provider #default="{ errors }" :name="$t('locales.language')" rules="required">
                            <v-select v-model="object.language" :options="localeObjects()" :reduce="item => item.id" :clearable="false">
                                <template #selected-option="{label,img}" style="max-width: 90%;" >
                                    <div >
                                        <b-img height="14px" width="22px" :src="img"/>
                                        {{label}}

                                    </div>
                                </template>
                                <template v-slot:option="option">
                                    <b-img height="14px" width="22px" :src="option.img"/>
                                    {{option.label}}
                                </template>
                            </v-select>
                            <small class="text-danger">{{errors[0]}}</small>
                        </validation-provider>
                    </b-form-group>

                </validation-observer>
                <div v-if="editing">
                    <div>
                        {{$t('user.user_added_on_clients')}}
                    </div>

                    <basic-table :columns="columns" :pageLength="8" :lengths="['8']" :data="client_ids" v-slot="props" >
                        <span v-if="props.column.field === 'id'">
                            {{getClientName(props.row.id) }}
                        </span>
                        <span class="d-flex justify-content-center" v-else-if="props.column.field === 'actions'">
                            <b-button variant="danger" class="" @click="remove(props.row.id)"><feather-icon icon="Trash2Icon"/></b-button>
                        </span>
                    </basic-table>
                </div>
            </b-overlay>
        </template>

        <template #modal-footer>
            <b-button variant="primary" @click="edit" :disabled="loading">
                <span>
                    <feather-icon icon="EditIcon" class="mr-50"/>
                    <span class="align-middle">{{$t('general.edit')}}</span>
                </span>
                <b-spinner v-if="loading" class="ml-1" style="height: 15px; width: 15px"/>
            </b-button>
        </template>
    </b-modal>
</template>
<script>
    import {BButton, BFormGroup, BFormInput, BModal, BOverlay, BSpinner, BImg} from 'bootstrap-vue'
    import { ValidationProvider, ValidationObserver } from 'vee-validate'
    import { required, email } from '@validations'
    import BasicTable from '@/views/components/BasicTable.vue'
    import {captureException} from '@sentry/vue'
    import vSelect from 'vue-select'
    import {localeObjects, Locales} from '@/libs/enums/Locales'

    export default {
        props: {
            clients: {
                type: Array
            }
        },
        components: {
            BOverlay,
            BSpinner,
            BasicTable,
            BFormGroup,
            BFormInput,
            BModal,
            BButton,
            ValidationProvider,
            ValidationObserver,
            BImg,
            vSelect
        },
        computed: {
            Locales() {
                return Locales
            },
            client_ids() {
                const array = []
                if (this.object && this.object.client_ids && this.object.client_ids.length > 0) {
                    for (const item of this.object.client_ids) {
                        array.push({
                            id: item
                        })
                    }
                }

                return array
            }
        },
        data() {
            return {
                loading: false,
                modalActive: false,
                object: {
                    name: '',
                    email: '',
                    password: '',
                    client_ids: [],
                    language:'sl'
                },
                editing: true,
                disabledEmail: true,
                required,
                email,
                columns: [
                    {
                        label: this.$t('general.name'),
                        field: 'id'
                    },
                    {
                        label: this.$t('table.options'),
                        field: 'actions'
                    }
                ]

            }
        },
        methods: {
            localeObjects,

            async remove(clientId) {
                try {
                    await this.$http.delete(`/api/reseller/v1/client/${clientId}/${ this.object.id}`)
                    this.$printSuccess(this.$t('success.on_delete'))

                    const res = await this.$http.get(`/api/reseller/v1/users/${ this.object.id}`)
                    this.object = res.data
                    this.$emit('itemAdded')
                } catch (error) {
                    captureException(error)
                    this.$printError(this.$t('error.on_delete'))
                }
            },
            getClientName(client_id) {
                const item = this.clients.find(ele => ele.id === client_id)

                if (item) {
                    return item.name
                }

                return ''
            },
            open(data = null) {
                if (data !== null) {
                    this.object = JSON.parse(JSON.stringify(data))
                    this.editing = true
                    this.disabledEmail = true
                    this.loading = false
                    this.modalActive = true
                }
            },
            async edit() {
                const valid = await this.$refs.validation.validate()
                if (!valid) return

                try {
                    this.loading = true
                    await this.$http.put(`/api/reseller/v1/users/${this.object.id}`, this.object)
                    this.modalActive = false
                    this.$emit('itemAdded')
                    this.$printSuccess(this.$t('success.on_edit'))
                    this.object = {
                        name: '',
                        email: '',
                        password: '',
                        language:'sl'
                    }
                } catch (error) {
                    captureException(error)
                    if (error && error.response && error.response.data && (error.response.data.error === 'user with this email exists')) {
                        this.$printError(this.$t('error.user_with_email_exists'))
                    } else {
                        this.$printError(this.$t('error.on_edit'))
                    }
                } finally {
                    this.loading = false
                }
            }
        }
    }
</script>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
</style>